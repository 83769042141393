import { format, differenceInMilliseconds, addDays } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { ymdDash, resetTimeDefault } from '~configs/dateFormatStr';
import dateTime from '~utils/date-time';
import { Instrument, ClosureDateTimes } from '~types/common';

export const closedTimeLookup: { [key: string]: string } = {
  '3000': '10:30:00.000z', // 'XAU/USD AM'
  '3002': '12:00:00.000z', // 'XAG/USD AM'
  '3001': '15:00:00.000z', // 'XAU/USD PM'
};

export const marketClosureDateTimes = (
  ymd: string,
  metalFixingInstruments: Instrument[]
): ClosureDateTimes => {
  const dt: ClosureDateTimes = {};
  metalFixingInstruments.forEach((inst: Instrument) => {
    const instId: string = `${inst.instrument_id}`;
    const closedTime = closedTimeLookup[instId];
    if (inst.fixing_time || closedTime) {
      dt[inst.instrument_id] = new Date(
        `${ymd}T${inst.fixing_time || closedTime}`
      );
    }
  });

  return dt;
};

export const isClosed = (currentDT: Date, closedDT: Date) => {
  const nyTime = dateTime.getEtcDateFromUserTimeZone(currentDT);
  const ny5pm = toDate(`${format(nyTime, ymdDash)} ${resetTimeDefault}`, {
    timeZone: 'America/New_York',
  });

  // closed at next day
  let realClosedDT = closedDT;
  if (dateTime.isSameOrAfter(currentDT, ny5pm)) {
    realClosedDT = addDays(closedDT, 1);
  }

  return (
    dateTime.isSameOrAfter(currentDT, realClosedDT) ||
    dateTime.getIsOutOfHours(nyTime, 'America/New_York')
  );
};

export const reOpenAt = (currentDT: Date) => {
  const nyTime = dateTime.getEtcDateFromUserTimeZone(currentDT);
  const ny5pm = toDate(`${format(nyTime, ymdDash)} ${resetTimeDefault}`, {
    timeZone: 'America/New_York',
  });

  const diff = differenceInMilliseconds(ny5pm, currentDT);

  const weekDay = nyTime.getDay();

  if (weekDay === 5) {
    return diff + 2 * 24 * 3600 * 1000;
  }

  if (weekDay === 6) {
    return diff + 24 * 3600 * 1000;
  }

  return diff;
};

export const fixingSourceOptions = (
  metalFixingInstruments: Instrument[],
  source: number | string
) =>
  metalFixingInstruments
    .filter(
      (i: Instrument) => i.instrument_id === source || i.symbol === source
    )
    .map((i: Instrument) => ({
      instrument_id: i.instrument_id,
      tenor: i.tenor,
    }));

export const showFixingStatus = (status: string, closeStatus: string) =>
  closeStatus === 'closed' && ['ACTIVE', 'NEW'].includes(status)
    ? 'ACCEPTED'
    : status;

export const disabledBtnAtMarketClosed = (closeStatus: string) =>
  closeStatus === 'closed';
